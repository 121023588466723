
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="item" class="col-12">
      <h5 class="mb-3">Application Details</h5>
      <div class="row g-4">
        <div class="col-lg-8">
          <div class="card mb-4 overflow-hidden">
            <div class="card-body">
              <div class="row">
                <div class="col-10 col-sm-8">
                    <div class="d-flex align-items-center justify-content-start mb-3">
                        <div class="">
                            <div class=""><strong>Status:</strong> <span v-html="statusBadge(item.status)"></span></div>
                        </div>
                    </div>
                </div>
                <div class="col-2 col-sm-4 text-end">
                  <div class="dropdown dropstart no-arrow">
                    <button class="dropdown-toggle btn btn-sm btn-light" type="button"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <ul class="dropdown-menu">
                        <li>
                        <a class="dropdown-item" :href="receiptUrl" target="_black">
                          Generate receipt </a>
                        </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12">
                    <div class="row g-3">
                      <div class="col-sm-6">
                        <label class="small">Application Number</label>
                        <h6 class="mb-0">{{ item.friendly_id }}</h6>
                      </div>
                      <div class="col-sm-6">
                        <label class="small">Business name</label>
                        <h6 class="mb-0">{{ item.business_name }}</h6>
                      </div>
                      <div class="col-sm-6">
                        <label class="small">Business Address</label>
                        <h6 class="mb-0">{{ item.business_address }}</h6>
                      </div>
                      <div class="col-sm-6">
                        <label class="small">Business LGA</label>
                        <h6 v-if="item.lga" class="mb-0">{{ item.lga.name }}</h6>
                      </div>
                      <div class="col-sm-6">
                        <label class="small">Trade</label>
                        <h6 v-if="item.trade" class="mb-0">{{ item.trade.title }}</h6>
                      </div>
                      <div class="col-sm-6">
                        <label class="small">Pays taxes/TIN</label>
                        <h6 class="mb-0">{{item.pays_tax ? 'YES / ' : 'NO' }} {{ item.business_tin }}</h6>
                      </div>
                      <div class="col-sm-6">
                        <label class="small">Type of Business</label>
                        <h6 class="mb-0">{{ item.business_type || item.business_type_other }}</h6>
                      </div>
                      <div class="col-sm-6">
                        <label class="small">Year Established</label>
                        <h6 class="mb-0">{{ item.business_year }}</h6>
                      </div>
                      <div class="col-sm-6">
                        <label class="small">Number of Employees</label>
                        <h6 class="mb-0">{{ item.number_employees }}</h6>
                      </div>
                      <div class="col-sm-6">
                        <label class="small">Annual Revenue Range</label>
                        <h6 class="mb-0">{{ item.annual_revenue }}</h6>
                      </div>
                      <div class="col-sm-6">
                        <label class="small">Market Reach</label>
                        <h6 class="mb-0">{{ item.market_reach }}</h6>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card shadow-sm">
            <div class="card-header">
              <h6 class="card-title mb-0">Equipment and Machinery Needs</h6>
            </div>
            <div class="card-body">
              <div  class="row g-4"> 
                <div class="col-12">
                  <label class="small">Current Equipment and Machinery Used: </label>
                  <div class="mb-0 fw-bold" v-html="preText(item.equipment_owned)" > </div>
                </div>
                <div class="col-12">
                  <label class="small">Type of equipment or machinery seeking for support: </label>
                  <div class="mb-0 fw-bold" v-html="preText(item.equipment_needed)" > </div>
                </div>
                <div class="col-12">
                  <label class="small">Challenges currently facing with existing equipment: </label>
                  <div class="mb-0 fw-bold" v-html="preText(item.equipment_challenges)" > </div>
                </div>
                <div class="col-12">
                  <label class="small">Benefits of new equipment or machinery in business:</label>
                  <div class="mb-0 fw-bold" v-html="preText(item.equipment_benefit)" > </div>
                </div>
                <div class="col-12">
                  <label class="small">Concerns regarding the data collection process</label>
                  <div class="mb-0 fw-bold" v-html="preText(item.data_collection_consern)" > </div>
                </div>
                <div class="col-sm-6">
                  <label class="small">Updates and notifications</label>
                  <h6 class="mb-0">{{ item.updates_consent ? "YES" : "NO" }}</h6>
                </div>
                <div class="col-sm-6">
                  <label class="small">Data usage consent</label>
                  <h6 class="mb-0">{{ item.data_usage_consent ? "YES" : "NO" }}</h6>
                </div>
              </div>
            </div>
          </div>
          <div v-if="item.beneficiary" class="card shadow-sm">
            <div class="card-header">
              <h6 class="card-title mb-0">Beneficiary Details</h6>
            </div>
            <div class="card-body">
              <div  class="row g-4"> 
                <div class="col-12">
                  <div class="d-flex align-items-center">
                    <img @click="showProfileImg(item.beneficiary.image)" 
                    class="avatar me-3 rounded-circle cursor-pointer" :src="absoluteUrl(item.beneficiary.image)" />
                    <div>
                      <label class="small">Full Name</label>
                      <h6 class="mb-0">{{ item.beneficiary.first_name }} {{ item.beneficiary.middle_name }} {{ item.beneficiary.last_name }}</h6>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="small">Phone</label>
                  <h6 class="mb-0">{{ item.beneficiary.mobile }}</h6>
                </div>
                <div class="col-sm-6">
                  <label class="small">Gender</label>
                  <h6 class="mb-0">{{ item.beneficiary.gender }}</h6>
                </div>
                <div class="col-sm-6">
                  <label class="small">Position in Business</label>
                  <h6 class="mb-0">{{ item.beneficiary.position }}</h6>
                </div>
                <div class="col-sm-6">
                  <label class="small">Address</label>
                  <h6 class="mb-0">{{item.beneficiary.address }}</h6>
                </div>
                <div class="col-sm-6">
                  <label class="small">Ward</label>
                  <h6 v-if="item.beneficiary.ward" class="mb-0">{{ item.beneficiary.ward.name }}</h6>
                </div>
                <div class="col-sm-6">
                  <label class="small">Local Government Area</label>
                  <h6 v-if="item.beneficiary.lga" class="mb-0">{{ item.beneficiary.lga.name }}</h6>
                </div>
              </div>
            </div>
          </div>
          <div v-if="item.bank_account" class="card shadow-sm">
            <div class="card-header">
              <h6 class="card-title mb-0">Bank Account Details</h6>
            </div>
            <div class="card-body">
              <div class="row g-3">
                <div class="col-12"><strong>Account name:</strong> <span>{{item.bank_account.account_name}}</span></div>
                <div class="col-12"><strong>Account Number:</strong> <span>{{item.bank_account.account_number}}</span></div>
                <div class="col-12"><strong>Bank:</strong> <span v-if="item.bank_account.bank">{{item.bank_account.bank.name}}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="position-sticky" style="top: 90px;">
            <business-documents-card :items="item.documents"
            @showImages="($event) => lightbox = $event" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
  <vue-easy-lightbox :visible="lightbox.visible" :imgs="lightbox.files"
  :index="lightbox.index" @hide="initializeAll()"></vue-easy-lightbox>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import BusinessDocumentsCard from '@/components/cards/BusinessDocumentsCard.vue'
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: "application-show",
  components:{
    IsLoading,
    BusinessDocumentsCard,
    VueEasyLightbox
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      item: null,
      lightbox:{
        index: null,
        visible:false,
        files: []
      },
    }
  },
  watch: {
    '$route.params.applicationId': function (id){
      if(id) this.fetchItem()
    },
  },
  computed: {
    receiptUrl(){
      let searchParams = new URLSearchParams({
        application_id: this.item.friendly_id,
        phone_number: this.item.beneficiary.mobile
      }).toString();

      let fullUrl = this.absoluteUrl('/applications/status')
      return `${fullUrl}?${searchParams}`
    }
  },
  methods: {
    updateItem(item){
      Object.assign(this.item, item)
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/applications/${this.$route.params.applicationId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.item = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    showProfileImg(image){
      this.lightbox = { index: 0, visible: true, files: [ this.absoluteUrl(image)] }
    },
    initializeAll(){
      this.popupModalShow = false;
      this.lightbox = { index: null, visible:false, files: [] }
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

