
<template>
  <div class="row">
    <div class="col-12">
      <h5 class="mb-3">Create New Application</h5>
      <div class="">
        <application-lookup-form v-if="formState == 'closed'" @foundItem="foundForm($event)"/>
        <application-form v-if="formState == 'open'"
          :form="form"
           @addItem="afterFormSubmission($event)" 
         />
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationForm from '@/components/forms/ApplicationForm.vue';
import ApplicationLookupForm from '@/components/forms/ApplicationLookupForm.vue';

export default {
  components: { 
    ApplicationForm,
    ApplicationLookupForm
  },
  data(){
    return{
      formState: 'closed',
      form: null
    }
  },
  methods:{
    afterFormSubmission(item){
      this.formState = 'closed'
      this.form = null

      let searchParams = new URLSearchParams({
        application_id: item.friendly_id,
        phone_number: item.beneficiary.mobile
      }).toString();

      let fullUrl = this.absoluteUrl('/applications/status')
      Swal.fire({
        title: "Application submitted successfully",
        icon: "success",
        html: `<h3>Application Number is: ${item.friendly_id} </h3><p>The status of the application is visible via <a href="${fullUrl}?${searchParams}">${fullUrl}</a></p>
         <a href="${fullUrl}?${searchParams}"
          class="btn btn-primary" target="_blank"><i class="mdi mdi-cloud-print-outline"></i> Print Receipt </a>`,
        showConfirmButton: false,
      });
    },
    foundForm(item){
      this.form = item
      this.formState = 'open'
    }
  }
}
</script>
