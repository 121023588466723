
<template>
<div class="user-login">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="col-lg-12">
          <div class="card overflow-hidden">
              <div class="row g-0">
                  <div class="col-lg-6">
                      <div class="p-lg-5 p-4 auth-one-bg h-100"
                          :style="`background-image: url(${absoluteUrl('/images/bg/techie-kwzWjTnDPLk-unsplash.jpg')});`">
                          <div class="bg-overlay"></div>
                          <div class="position-relative h-100 d-flex flex-column">
                              <div class="mb-4">
                                  <a :href="absoluteUrl()" class="d-block">
                                    <img :src="absoluteUrl('/images/logos/logo-dark.webp')"  alt="" height="30">
                                  </a>
                              </div>
                              <div class="mt-auto text-white">
                                  <div>
                                    <h1 class="fw-bold text-white">Arise Sustainable Business Support Scheme</h1>
                                  </div>
                                  <div class="mb-3">
                                    <i class="ri-double-quotes-l display-4 text-warning"></i>
                                  </div>

                                  <div id="qoutescarouselIndicators" class="carousel slide pointer-event" data-bs-ride="carousel">
                                      <div class="carousel-indicators">
                                        <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="0" class="" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="1" aria-label="Slide 2" class="active" aria-current="true"></button>
                                        <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="2" aria-label="Slide 3" class=""></button>
                                      </div>
                                      <div class="carousel-inner text-center text-white-70 pb-5">
                                        <div class="carousel-item">
                                          <h6 class="text-white">Transparent and Inclusive Access</h6>
                                          <p class="fs-15 fst-italic">"Ensuring easy, fair access to support with clear eligibility criteria and real-time updates."</p>
                                        </div>
                                        <div class="carousel-item active">
                                          <h6 class="text-white">Empowering Nano Businesses</h6>
                                          <p class="fs-15 fst-italic">"Supporting business owners of Akwa Ibom Origin with equipment for sustainable growth and long-term success."</p>
                                        </div>
                                        <div class="carousel-item">
                                          <h6 class="text-white">Tech-Driven Public Service</h6>
                                          <p class="fs-15 fst-italic">"Leveraging innovative technology for efficient application processes and secure transactions."</p>
                                        </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="p-lg-5 p-4">
                          <div>
                            <h1 class="h3 text-primary">Welcome Back !</h1>
                            <p class="text-muted">Sign in to access your account.</p>
                          </div>
                          <div class="mt-4">
                            <user-login-form @takeAction="takeAction($event)" />
                          </div>
                          <div class="mt-5 text-center">
                              <!-- <p class="mb-0">Don't have an account ? <a :href="absoluteUrl('/register')" class="fw-semibold text-primary text-decoration-underline"> Create Account</a> </p> -->
                          </div>
                      </div>
                  </div>
                  <!-- end col -->
              </div>
              <!-- end row -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import UserLoginForm from '@/components/forms/UserLoginForm.vue'

export default {
  components:{
    UserLoginForm
  },
  methods: {
    takeAction(data){
      this.$store.commit('SET_AUTH_TOKEN', data);
      if(this.$route.query.intended){
        location.replace(this.$route.query.intended)
      }else{
        location.replace('/')
      }
    },
   },
  }
</script>


<style>
.user-login .auth-one-bg {
  background-position: center;
  background-size: cover;
}

.user-login .auth-one-bg .bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  background-color: #000;
  background: linear-gradient(134deg, rgba(0, 0, 0, 1) 0%, rgba(0, 125, 83, 0.7) 400px);;
  opacity: 1;
}

.user-login .text-white-70{
  color: rgba(255, 255, 255, .7) !important
}

</style>