export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '/main',
      redirect: '/',
      component: () => import('@/layouts/Main.vue'),
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: () => import('@views/Dashboard.vue'),
          meta: {
            title: 'Dashboard',
            authRequired: true,
          }
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('@views/account/UserEdit.vue'),
          meta: {
            title: 'Account',
            authRequired: true,
          },
        },
        {
          path: '/applications',
          name: 'applications',
          component: () => import('@views/applications/Applications.vue'),
          meta: {
            title: "List of Applications",
            authRequired: true,
          }
        },
        {
          path: '/collections',
          name: 'collections',
          component: () => import('@views/invitations/Invitations.vue'),
          meta: {
            title: "Collections",
            authRequired: true,
          }
        },
        {
          path: '/applications/create',
          name: 'application-create',
          component: () => import('@views/applications/ApplicationCreate.vue'),
          meta: {
            title: "Create New Application",
            authRequired: true,
          }
        },
        {
          path: '/applications/:applicationId(\\d+)',
          name: 'application-show',
          component: () => import('@views/applications/ApplicationShow.vue'),
          meta: {
            title: "View Application",
            authRequired: true,
          },
        },
        {
          path: '/notifications',
          name: 'notifications',
          component: () => import('@views/notifications/NotificationList.vue'),
          meta: {
            title: "Notifications",
            authRequired: true,
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '/full',
        component: () => import('@/layouts/Full.vue'),
        children: [
          {
            path: '/login',
            name: 'login',
            component: () => import('@/views/pages/Login.vue'),
            meta: {
              guestRequired: true,
              title: "Login"
            }
          },
          {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/pages/Error404.vue'),
            meta: {
              title: "404 - Page not Found"
            }
          },
          {
            path: '/error-500',
            name: 'error-500',
            component: () => import('@/views/pages/Error500.vue'),
            meta: {
              title: "500 - Server error"
            }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '/:any(.*)',
        redirect: '/error-404'
    },

]