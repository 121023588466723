
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Applications</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-lg-12 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <!-- <li @click.prevent="exportCases()">
                <button class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li> -->
            </ul>
          </div>
          <router-link class="btn btn-primary" to="/applications/create">Register Applicant</router-link>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="input-group w-auto">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select v-model="defaultStatus" class="form-select flex-grow-1">
              <option value="" disabled selected>-- sort by status --</option>
              <option :value="null" key="-2" > All status </option>
              <option v-for="(status, tIndex) in statusOptions"
                :value="status.value" :key="tIndex" > {{status.label}} </option>
            </select>
          </div>
        </div>
        <div class="col-md-5">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm"  align="middle">
              <template v-for="(column, colIndex) in columns.filter((i) => i.sortable)"
                v-slot:[`head(${column.key})`] >
                  <div :key="colIndex" @click="setSortOrder(column)" class="cursor-pointer
                  d-flex align-items-center">
                    {{ column.label }} <span v-html="getSortingLabel(column.key)"></span>
                  </div>
              </template>
              <template v-slot:cell(friendly_id)="data">
                <div style="min-width:10rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.friendly_id }}
                </div>
              </template>
              <template v-slot:cell(beneficiary)="data">
                <div @click="navigateTo(data.item.id)" style="min-width:10rem;"
                v-if="data.item.beneficiary"  class="cursor-pointer">
                  <h6>{{ data.item.beneficiary.first_name }} {{ data.item.beneficiary.last_name }}</h6>
                  <span class="text-dark font-sm">{{ data.item.beneficiary.mobile }}</span>
                </div>
              </template>
              <template v-slot:cell(business_name)="data">
                <div style="min-width:10rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.business_name }}
                </div>
              </template>
              <template v-slot:cell(trade)="data">
                <div style="min-width:7rem;" v-if="data.item.trade" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.trade.title }}
                </div>
              </template>
              <template v-slot:cell(business_address)="data">
                <div style="min-width:10rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.business_address }}
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span v-html="statusBadge(data.item.status)"></span>
                </div>
              </template>
              <template v-slot:cell(pays_tax)="data">
                <div @click="navigateTo(data.item.id)" style="min-width:6rem;" class="cursor-pointer">
                  <span v-if="data.item.pays_tax" class="badge bg-primary font-sm"> YES</span>
                  <span v-else class="badge bg-primary font-sm"> NO</span>
                </div>
              </template>   
              <template v-slot:cell(created_at)="data">
                <div @click="navigateTo(data.item.id)" style="width:7rem;" class="cursor-pointer">
                  {{$filters.date(data.item.created_at, 1) }} 
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import IsLoading from '@/components/IsLoading.vue';



export default {
  name: "application-list",
  components:{
    IsLoading,
  },
  data() {
    return {
      isLoading: false,
      defaultStatus: null,
      searchQuery: "",
      pageOptions: [10, 50, 100, 500, 1000],
      columns: [
        {
          key: "friendly_id",
          label: "Number",
          sortable: true
        },
        {
          key: "beneficiary",
          label: "Applicant",
        },
        {
          key: "business_name",
          label: "Business name",
          sortable: true
        },
        {
          key: "trade",
          label: "Trade",
        },
        {
          key: "business_address",
          label: "Business Address",
          sortable: true
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "pays_tax",
          label: "Pays tax",
          sortable: true
        },
        {
          key: "created_at",
          label: "Created at",
          sortable: true
        },
      ],
      statusOptions:[
        { value: 'pending', label: "Pending"},
        { value: 'shortlisted', label: "Shortlisted"},
        { value: 'approved', label: "Approved"},
        { value: 'rejected', label: "Rejected"},
        { value: 'collected', label: "Collected"}
      ],
      sortBy: {
        sort_column: "created_at",
        sort_direction: "desc"
      },
      pageData:{
        data: []
      }
    }
  },
  watch: {
    "$route.query" : "refreshQuery",
    defaultStatus (val) {
      this.fetchItems()
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
    getSortingLabel(){
      return key => {
        let iconClass = 'text-muted ri-arrow-up-line';
        if(this.sortBy.sort_column != key) return `<span class='ms-2 ${iconClass}'></span>`;
        iconClass = this.sortBy.sort_direction == 'desc' ? 'ri-arrow-up-line' : 'ri-arrow-down-line';
        return `<span class='ms-2 ${iconClass}'></span>`
      }
    }
  },
  methods: {
    navigateTo(id){
      this.$router.push({path: `/applications/${id}`});
    },
    refreshQuery(){
      if(this.$route.query.status){
        this.defaultStatus = this.$route.query.status
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage,
        ...this.sortBy
      }
      if(this.defaultStatus){ payload.status = this.defaultStatus }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$http.get(`/applications?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.pageData = response.data.data
            // this.selectedItems = []
          }
        })
    },
    setSortOrder(column) {
      if (this.sortBy.sort_column === column.key) {
        this.sortBy.sort_direction = this.sortBy.sort_direction === 'desc' ? 'asc' : 'desc';
      } else {
        this.sortBy.sort_column = column.key;
        this.sortBy.sort_direction = 'desc';
      }
      this.fetchItems();
    },
    exportCases() {
      const payload = {
        url: '/applications/export-excel',
        filename: 'applications_'
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.exelFileExport(payload)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
            this.alertError('Could not generate applications');
          }
      })
    },
    initializeAll(){
    }
  },
  mounted(){
    this.fetchItems();
  }
}
</script>

