<template>
  <div>
    <qrcode-stream @decode="onDecode" />
  </div>
</template>
<script>
import { QrcodeStream } from "vue3-qrcode-reader";

export default {
    components: {
        QrcodeStream,
    },
    methods: {
        onDecode(result) {
            this.$emit('foundItem', result)
        },
    },
}
</script>

<style>

</style>