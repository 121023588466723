
import { createStore } from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleLayout from "./modules/layout.js"
import moduleNotifications from "./modules/notifications.js"
import moduleCategory from './modules/categories.js'
import moduleSubCategory from './modules/sub-categories.js'
import moduleMedicineType from './modules/medicine-types.js'
import moduleMedicine from './modules/medicines.js'
import moduleHospital from './modules/hospitals.js'
import moduleAllocation from './modules/allocations.js'

const store = createStore({
    state,
    actions,
    getters,
    mutations,
    modules: {
        layout: moduleLayout,
        notification: moduleNotifications,
        subCategory: moduleSubCategory,
        category: moduleCategory,
        medicineType: moduleMedicineType,
        medicine: moduleMedicine,
        hospital: moduleHospital,
        allocation: moduleAllocation,
    },
    strict: process.env.NODE_ENV !== 'production',
})

export default store
