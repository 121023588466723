
<template>
<form autocomplete="off" class="patient-lookup-form">
  <div class="card loading-viewport">
    <is-loading v-if="isLoading" :box="true" />
    <div class="card-body text-center">
      <h5 class="mb-4 mb-lg-5">Click the button below to Scan Form's QR Code</h5>

      <button @click.prevent="popupModalQrCode = true" class="btn mb-0 me-2 btn-dark"  type="button">
        <i class="mdi mdi-camera-account"></i> Scan QR Code
      </button>

    </div>
  </div>
  <b-modal v-model="popupModalQrCode" hide-footer centered
    title="Scan QR Code"
    title-class="fs-6" @hide="resetForm()">
    <qr-code-scanner v-if="popupModalQrCode" 
     @foundItem="lookup($event)"/>
  </b-modal>
</form>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import QrCodeScanner from '@/components/forms/QrCodeScanner.vue';

export default {
  components: {
    IsLoading,
    QrCodeScanner
  },
  data() {
    return {
      isLoading: false,
      popupModalQrCode: false,
    }
  },
  methods: {
    lookup(code){
      this.popupModalQrCode = false
      if(!code){this.alertError('Form code is required'); return }
      const payload = { form_id: code }
      this.isLoading = true
        this.$http.get(`/forms/lookup?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.$emit('foundItem', response.data.data)
            this.resetForm();
          }else{
            this.$emit('notFound', null)
          }
        })
    },
    resetForm() {
      this.popupModalQrCode = false
    },
  },
}
</script>

<style>
</style>