<template>
    <div>
      <div class="d-grid d-sm-flex gap-2">
          <button type="button"  @click="popupTakePicture =  true" class="btn w-100 btn-outline-dark">
                  <span class="mdi mdi-camera"></span> Take Picture </button>
          <button type="button" class="btn w-100 btn-outline-primary" @click="triggerFileInput">Upload Image</button>
        
          <input type="file" accept="image/*" ref="fileInput" style="display:none;" @change="handleFileChange">
      </div>
      <b-modal hide-footer hide-header  v-model="popupTakePicture" centered
          no-close-on-esc no-close-on-backdrop class="web-cam-moal-backdrop" 
          @hide="popupTakePicture = false" body-class="bg-light">
          <vue-web-cam v-if="popupTakePicture"
              @acceptImage="acceptCameraImage($event)"
              @closeMe="popupTakePicture = false" />
      </b-modal>
    </div>
  </template>
  
  <script>
  import VueWebCam from '@/components/VueWebCam.vue';
  
  export default {
      components:{
          VueWebCam
      },
      props:{
        uploadUrl:{
            type: String,
            default: "/api/uploads"
        },
        maxSize:{
            type: Number,
            default: 2000000
        },
        includeDataUrl: {
            type: Boolean,
            default: false
        }
      },
      data(){
          return{
            data:{},
            popupTakePicture: false
          }
      },
      methods:{
          uploadFile(file){
              let formData = new FormData();
              formData.append('image_file', file);
              this.$store.dispatch("changeLoaderValue", true)
              this.$http.post(this.absoluteUrl(this.uploadUrl), formData)
              .then((response) => {
                  this.$store.dispatch("changeLoaderValue")
                  if(response.data.success){
                    this.data.path = response.data.data
                    this.$emit('uploadSuccess', this.data)
                    this.resetForm()
                  }
              })
          }, 
          acceptCameraImage(data){
            this.fillFileDetails(data.file);
            if(this.includeDataUrl){
                this.data.imgSrc = data.url;
            }
            this.uploadFile(data.file)
          },
          triggerFileInput() {
              this.$refs.fileInput.click();
          },
          handleFileChange(event) {
            const file = event.target.files[0];
            if(file.size > this.maxSize){
                this.alertError(`File size is more than ${this.$filters.getReadableFileSize(this.maxSize)}`); return;
            }
            this.fillFileDetails(file);
            if(!this.includeDataUrl){
                this.uploadFile(file); return;
            }
            this.$filters.fileReader(file)
                .then(result => {
                    this.data.imgSrc = result.dataURL;
                    this.uploadFile(file)
                })
                .catch(error => {
                    this.alertError('Error reading file '+error);
                });
          },
          fillFileDetails(file){
            this.data.name = file.name
            this.data.type = file.type
            this.data.extension = this.$filters.getFileExtension(file.name)
            this.data.size = this.$filters.getReadableFileSize(file.size)
          },
          resetForm() {
            this.data = { imgSrc: "" }
            this.popupTakePicture = false
          },
      }
  }
  </script>
  
  <style>
  
  </style>