<template>
  <div class="dashboard">
    <div class="row gy-3 mb-3">
      <div class="col-md-12">
        <h5 class="mb-2">Hi, {{activeUser.first_name}}</h5>
        <p>
          Welcome to the Arise Sustainable Business Support Scheme Staff Dashboard — your central platform for efficiently managing and registering business support initiatives.</p>
      </div>
    </div>
    <div v-if="result.user" class="row gy-3 mb-3">
      <div v-if="result.user && result.user.assigned_lga" class="col-12">
        <div class="row mb-4 g-4 g-lg-5 mb-lg-5">
          <div class="col-md-4">
            <div class="bd-callout mt-0 mb-0 bd-callout-warning">
              <h6>Your Assigned Local Government</h6>
              <div>
                <h5 class="mb-0"> {{result.user.assigned_lga.name}} </h5>
                <p v-if="result.user.assigned_lga.senatorial_district" class="mb-1">
                  <span>  {{ result.user.assigned_lga.senatorial_district.name }} Senatorial district</span>
                </p>
                <div class="d-grid d-sm-block pt-3">
                  <router-link class="btn btn-primary" to="/applications/create">Register Applicant</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-0 h-100 text-center">
              <div class="card-body position-relative">
                <h6 class="text-primary"> Total Applications</h6>
                <h2 class="my-1">{{result.applications.total}}</h2>
                <p> You have created {{result.applications.total}} total application{{ result.applications.total > 1 ? 's':''}}</p>
                <router-link class="text-decoration-underline" to="/applications"> View Applications </router-link>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-0 h-100 text-center">
              <div class="card-body position-relative">
                <h6 class="text-primary"> Your Account</h6>
                <h2 class="my-1"> <i class="mdi mdi-account-edit-outline"></i></h2>

                <p>Information & setttings</p>
                <router-link class="text-decoration-underline" to="/account"> Account Settings </router-link>
              </div>
            </div>
          </div>
        </div>  
        <div class="row mb-4 mb-lg-5">
          <div class="col-lg-4 order-lg-2">
            <div class="position-sticky" style="top: 90px;">
              <div class="card overflow-hidden">
                <div class="bg-primary py-4">
                </div>
                <div class="card-body text-center">
                  <a href="javascript:void(0)" class="avatar rounded-circle avatar-lg card-avatar card-avatar-top">
                    <img :src="absoluteUrl(activeUser.image)" class="avatar-img rounded-circle border border-4 border-card" alt="...">
                  </a>
                  <small>
                    <span class="text-success">●</span> Online
                  </small>
                  <h3 class="card-title">
                    <a href="javascript:void(0)">{{activeUser.first_name}} {{activeUser.last_name}}</a>
                  </h3>
                  <p class="small text-muted mb-2">
                    <!-- {{result.application.friendly_id}} -->
                  </p>
                  <p class="small text-muted mb-0">
                    <span class="badge bg-primary">Staff</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="card h-100">
              <div class="card-header">
                <h5 class="card-title mb-0">Your Profile</h5>
              </div>
              <div class="card-body position-relative">
                <div v-if="!isLoading && result.user"  class="row g-4"> 
                  <div class="col-sm-6">
                    <label class="small">First Name</label>
                    <h6 class="mb-0">{{ result.user.first_name }}</h6>
                  </div>
                  <div class="col-sm-6">
                    <label class="small">Middle Name</label>
                    <h6 class="mb-0">{{ result.user.middle_name }}</h6>
                  </div>
                  <div class="col-sm-6">
                    <label class="small">Last Name</label>
                    <h6 class="mb-0">{{ result.user.last_name }}</h6>
                  </div>
                  <div class="col-sm-6">
                    <label class="small">Email</label>
                    <h6 class="mb-0">{{ result.user.email }}</h6>
                  </div>
                  <div class="col-sm-6">
                    <label class="small">Phone</label>
                    <h6 class="mb-0">{{ result.user.mobile }}</h6>
                  </div>
                  <div class="col-sm-6">
                    <label class="small">Gender</label>
                    <h6 class="mb-0">{{ result.user.gender }}</h6>
                  </div>
                  <div class="col-sm-6">
                    <label class="small">Address</label>
                    <h6 class="mb-0">{{ result.user.address }}</h6>
                  </div>
                  <div class="col-sm-6">
                    <label class="small">Ward</label>
                    <h6 v-if="result.user.ward" class="mb-0">{{ result.user.ward.name }}</h6>
                  </div>
                  <div class="col-sm-6">
                    <label class="small">Local Government Area</label>
                    <h6 v-if="result.user.lga" class="mb-0">{{ result.user.lga.name }}</h6>
                  </div>
                  <div class="col-sm-6">
                    <label class="small">Registered On</label>
                    <h6 class="mb-0">{{ $filters.date(result.user.created_at) }}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <is-loading v-else />
    <b-modal size="lg" v-model="forceUpdateProfile" 
      hide-footer centered title="Update your profile"
      :no-close-on-backdrop="true" :no-close-on-esc="true"
      hide-header-close
      title-class="fs-6" @hide="initializeAll()">
      <p class="text-danger">Please take a moment to complete your profile, especially profile photo.</p>
      <account-profile-form :editItem="result.user" @closeMe="closeForceUpdate()" />
    </b-modal>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue';
import AccountProfileForm from '@/components/forms/AccountProfileForm.vue';

export default {
    name: 'dashboard',
    components:{
      IsLoading,
      AccountProfileForm 
    },
    data(){
      return {
        isLoading: true,
        forceUpdateProfile: false
      }
    },
    computed:{
      activeUser(){
        return this.$store.state.AppActiveUser;
      },
      result(){
        return this.$store.state.dashboardData
      }
    },
    methods:{
      fetchDashboardData(){
        this.isLoading = true
        this.$http.get('/analytics')
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            if(!response.data.data.user?.image){
              this.forceUpdateProfile = true
            }
            this.$store.commit('UPDATE_DASHBOARD', response.data.data)
          }
        })
      },
      closeForceUpdate(){
        this.fetchDashboardData()
        this.initializeAll()
      },
      initializeAll(){
        this.forceUpdateProfile = false;
      },
    },
    created(){
      this.fetchDashboardData()
    }
}
</script>

