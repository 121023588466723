<template>
    <form autocomplete="off" class="">
        <div class="card loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <div class="card-body">
                <div class="row gy-3 mb-4">
                    <div class="col-12" >
                        <h6 class="text-center text-muted mb-4">Ref: <span class="text-uppercase"> {{ this.$filters.slug(`aks ${form.id} ${form.title}`)}}</span></h6>
                        <h5 class="mb-2">Beneficiary's Profile</h5>
                    </div>
                    <div class="col-md-4 col-lg-2">
                        <label class="form-label d-block" for="ProfileImage">Profile photo</label>
                        <img v-if="data.beneficiary.image" width="100" class="" :src="absoluteUrl(data.beneficiary.image)" alt="profile logo" />
                        <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="100" height="100" viewBox="0 0 256 256" xml:space="preserve">
                            <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                <path d="M 90 90 H 77.615 c -21.744 -5.296 -43.487 -5.296 -65.231 0 H 0 V 0 h 90 V 90 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(200,209,230); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                <path d="M 67.319 68.576 l -4.863 -2.716 c -3.576 -1.997 -6.386 -4.994 -8.161 -8.53 c 2.468 -3.578 4.072 -8.116 4.271 -11.885 h 0.022 l 1.346 -6.015 c 0.298 -1.332 -0.306 -2.604 -1.346 -3.293 v -2.179 c 0 -6.227 -4.193 -13.335 -9.906 -15.582 l 0.009 -0.015 c -2.81 -1.268 -6.633 -1.184 -8.423 -0.718 c 1.849 0.664 2.835 2.557 0.425 3.426 l 0.022 0.001 c -5.402 1.796 -9.302 6.882 -9.302 12.887 v 2.179 c -1.041 0.689 -1.644 1.96 -1.346 3.293 l 1.346 6.015 h 0.022 c 0.199 3.769 1.802 8.307 4.271 11.885 c -1.775 3.536 -4.586 6.533 -8.161 8.53 l -4.863 2.716 c -6.357 3.55 -10.296 10.262 -10.296 17.543 V 90 h 65.231 v -3.881 C 77.615 78.838 73.676 72.126 67.319 68.576 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(247,247,247); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                            </g>
                        </svg>
                    </div>
                    <div class="col-md-5 col-lg-3 align-self-end">
                        <profile-image-capture @uploadSuccess="($event) => { data.beneficiary.image = $event }"/>
                    </div>
                    <div v-for="(item, index) in v$.data.beneficiary.image.$errors"
                        :key="index" class="col-12">
                        <span v-if="item.$message" class="small text-danger">{{ item.$message }}</span>
                    </div>
                </div>
                <div class="row mb-4 mb-lg-5 gy-3">
                    <div class="col-md-6 col-lg-4">
                        <label class="form-label" for="firstName">First Name <span class="text-danger">*</span></label>
                        <input id="firstName" type="text" class="form-control" v-model="data.beneficiary.first_name"
                        :class="{ 'is-invalid': v$.data.beneficiary.first_name.$error }" placeholder="First name" name="first_name" />
                        <div v-for="(item, index) in v$.data.beneficiary.first_name.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label class="form-label" for="middleName">Middle Name</label>
                        <input id="middleName" type="text" class="form-control" v-model="data.beneficiary.middle_name"
                        placeholder="Middle name" name="middle_name" />
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label class="form-label" for="LastName">Last Name <span class="text-danger">*</span></label>
                        <input id="LastName" type="text" class="form-control" v-model="data.beneficiary.last_name"
                        :class="{ 'is-invalid': v$.data.beneficiary.last_name.$error }"  placeholder="Last Name" name="last_name" />
                        <div v-for="(item, index) in v$.data.beneficiary.last_name.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label class="form-label" for="inputMobile">Phone Number <span class="text-danger">*</span></label>
                        <input id="inputMobile" autocomplete="false" type="text" class="form-control" v-model="data.beneficiary.mobile"
                            :class="{ 'is-invalid': v$.data.beneficiary.mobile.$error  }" placeholder="mobile" name="mobile" />
                        <div v-for="(item, index) in v$.data.beneficiary.mobile.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>  
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <label for="gender" class="form-label">Gender <span class="text-danger">*</span></label>
                        <select v-model="data.beneficiary.gender" name="gender" id="gender" class="form-select"
                        :class="{ 'is-invalid': v$.data.beneficiary.gender.$error }" >
                        <option disabled :value="null" >--select gender--</option>
                        <option v-for="(gender, gdIndex) in genderOptions" :key="gdIndex" 
                            :value="gender.value">{{gender.label}}</option>
                        </select>
                        <div v-for="(item, index) in v$.data.beneficiary.gender.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div> 
                    </div>

                    <div class="col-md-6 col-lg-5">
                        <label class="form-label" for="emailAddress">Email Address</label>
                        <input id="emailAddress" type="email" class="form-control" v-model="data.beneficiary.email"
                        placeholder="Email Address" name="email" />
                    </div>
                    <div class="col-lg-6">
                        <label class="form-label">
                            Date of birth <span class="text-danger">*</span>
                        </label>
                        <div class="input-group">
                            <select @change="createDOB()" v-model="manualDate.day" name="day"
                                :class="{ 'is-invalid': v$.data.beneficiary.dob.$error  }" class="form-select">
                                <option disabled value="">Day </option>
                                <option v-for="(day, dIndex) in days" :key="dIndex"
                                    :value="day.value">{{day.label}}</option>
                            </select>
                            <select @change="createDOB()" v-model="manualDate.month" name="month"
                            :class="{ 'is-invalid': v$.data.beneficiary.dob.$error  }" class="form-select">
                                <option disabled value="">Month</option>
                                <option v-for="(month, mIndex) in months" :key="mIndex"
                                :value="month.value">{{month.label}}</option>
                            </select>
                            <select @change="createDOB()" v-model="manualDate.year" name="year"
                                :class="{ 'is-invalid': v$.data.beneficiary.dob.$error  }" class="form-select">
                                <option disabled value=""> Year </option>
                                <option v-for="(year, yIndex) in years" :key="yIndex"
                                :value="year.value">{{year.label}}</option>
                            </select>
                        </div>
                        <div v-for="(item, index) in v$.data.beneficiary.dob.$errors"
                            :key="index" class="text-danger">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div> 
                    </div>
                    <div class="col-lg-6">
                        <label class="form-label" for="inputPosition">Position in the Business <span class="text-danger">*</span></label>
                        <input id="inputPosition" type="text" class="form-control" v-model="data.beneficiary.position"
                        placeholder="Position in the business" name="position"
                        :class="{ 'is-invalid': v$.data.beneficiary.position.$error  }" />
                        <div v-for="(item, index) in v$.data.beneficiary.position.$errors"
                            :key="index" class="text-danger">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div> 
                    </div>
                </div>
                <p class="mb-0">Fill the beneficiary's permanent home address</p>
                <hr class="mt-1">
                <div class="row mb-4 mb-lg-5 g-3">        
                    <div class="col-sm-12">
                        <label for="address" class="form-label">Home Address <span class="text-danger">*</span></label>
                        <input v-model="data.beneficiary.address"
                        id="address" :class="{ 'is-invalid': v$.data.beneficiary.address.$error }"
                        name="address" class="form-control" type="text" placeholder="Address">
                        <div v-for="(item, index) in v$.data.beneficiary.address.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div> 
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label for="local_government_id" class="form-label">Local Government Area <span class="text-danger">*</span></label>
                        <select @change="lgaChange()" v-model="data.beneficiary.local_government_id" name="local_government_id" id="local_government_id" class="form-select"
                            :class="{ 'is-invalid': v$.data.beneficiary.local_government_id.$error }">
                            <option disabled value="" >--choose LGA--</option>
                            <option v-for="(lga, lgIndex) in lgaOptions" :key="lgIndex" 
                            :value="lga.id">{{lga.name}}</option>
                        </select>
                        <div v-for="(item, index) in v$.data.beneficiary.local_government_id.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div> 
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label for="registration_area_id" class="form-label">Ward <span class="text-danger">*</span></label>
                        <select @change="wardChange()" v-model="data.beneficiary.registration_area_id" name="registration_area_id" id="registration_area_id" class="form-select"
                            :class="{ 'is-invalid': v$.data.beneficiary.registration_area_id.$error }">
                            <option disabled value="" >--choose ward--</option>
                            <option v-for="(ward, wIndex) in wardOptions" :key="wIndex" 
                            :value="ward.id">{{ward.name}}</option>
                        </select>
                        <div v-for="(item, index) in v$.data.beneficiary.registration_area_id.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div> 
                    </div>
                    <div class="col-lg-4">
                        <label for="polling_unit_id" class="form-label">Polling Unit <span class="text-danger">*</span></label>
                        <select v-model="data.beneficiary.polling_unit_id" name="polling_unit_id" id="polling_unit_id" class="form-select"
                        :class="{ 'is-invalid': v$.data.beneficiary.polling_unit_id.$error }">
                            <option disabled value="" >--select polling unit--</option>
                            <option v-for="(unit, uIndex) in unitOptions" :key="uIndex" 
                            :value="unit.id">{{unit.name}}</option>
                        </select>
                        <div v-for="(item, index) in v$.data.beneficiary.polling_unit_id.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div> 
                    </div>
                </div>
                <p class="mb-0">Fill the beneficiary's Identification details</p>
                <hr class="mt-1">
                <div class="row  g-3">        
                    <div class="col-12">
                        <div class="alert alert-warning" role="alert">
                        <strong>NOTE!</strong>
                            Please ensure that you enter the NIN, and VIN accurately, as they will be verified.
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label class="form-label" for="nin_number"> National Identification Number (NIN) <span class="text-danger">*</span></label>
                        <input v-model="data.beneficiary.nin" id="nin_number" class="form-control" type="number"
                        placeholder="NIN number" name="nin" :class="{ 'is-invalid': v$.data.beneficiary.nin.$error }">
                        <div v-for="(item, index) in v$.data.beneficiary.nin.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div> 
                    </div>
                    <div class="col-sm-6">
                        <label class="form-label" for="vin_number">Voter Identification Number (VIN) <span class="text-danger">*</span></label>
                        <input v-model="data.beneficiary.vin" id="vin_number" class="form-control" type="text"
                        placeholder="VIN" name="vin" :class="{ 'is-invalid': v$.data.beneficiary.vin.$error }" >
                        <div v-for="(item, index) in v$.data.beneficiary.vin.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <div class="card loading-viewport">
            <is-loading v-if="validAccount.loading" :box="true" />
            <div class="card-body">
                <div class="row gy-3">
                    <div class="col-12" >
                        <h5 class="mb-2">Beneficiary Account Details</h5>
                    </div>
                    <div class="col-sm-6">
                        <label class="form-label" for="account_number">Account number <span class="text-danger">*</span></label>
                        <input @blur="verifyAccount()" id="account_number" type="text" class="form-control"
                         v-model="data.bank_account.account_number"
                        placeholder="Account number" name="account_number"
                        :class="{ 'is-invalid': v$.data.bank_account.account_number.$error }" />
                        <div v-for="(item, index) in v$.data.bank_account.account_number.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label class="form-label" for="bank_id">Bank name <span class="text-danger">*</span></label>
                        <select @change="verifyAccount()" v-model="data.bank_account.bank_id"
                         name="bank_id" id="bank_id" class="form-select"
                        :class="{ 'is-invalid': v$.data.bank_account.bank_id.$error }">
                            <option disabled value="" >--select bank--</option>
                            <option v-for="(bank, bIndex) in bankOptions" :key="bIndex" 
                            :value="bank.id">{{bank.name}}</option>
                        </select>
                        <div v-for="(item, index) in v$.data.bank_account.bank_id.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                    </div>
                    <div class="col-12">
                        <span v-if="data.bank_account.account_name" class="text-primary">{{data.bank_account.account_name}}</span>
                        <small v-if="validAccount.message" class="text-danger">{{validAccount.message}}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="card loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <div class="card-body">
                <div class="row gy-3">
                    <div class="col-12" >
                        <h5 class="mb-2">Business Information</h5>
                    </div>
                    <div class="col-12">
                        <label for="business_name" class="form-label"> Business/Owners Name <span class="text-danger">*</span></label>
                        <input v-model="data.business_name" id="business_name" name="business_name"
                        class="form-control" type="text" placeholder="Business Name"
                        :class="{ 'is-invalid': v$.data.business_name.$error }">
                        <div v-for="(item, index) in v$.data.business_name.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label for="trade_id" class="form-label">Trade <span class="text-danger">*</span></label>
                        <select v-model="data.trade_id" name="trade_id"
                        id="trade_id" class="form-select"
                        :class="{ 'is-invalid': v$.data.trade_id.$error }">
                        <option disabled value="" >--Choose Trade--</option>
                        <option v-for="(trade, ctIndex) in tradeOptions" :key="ctIndex" 
                        :value="trade.id">{{trade.title}}</option>
                        </select>
                        <div v-for="(item, index) in v$.data.trade_id.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label class="form-label"> Type of Business <span class="text-danger">*</span></label>
                        <select v-model="data.business_type" name="business_type"
                            :class="{ 'is-invalid': v$.data.business_type.$error  }" class="form-select">
                            <option disabled value=""> --select type-- </option>
                            <option v-for="(businessType, yIndex) in businessTypes" :key="yIndex"
                            :value="businessType.value">{{businessType.label}}</option>
                        </select>
                        <input v-if="data.business_type === null" v-model="data.business_type_other" class="mt-2 form-control" type="text" placeholder="Enter business type">
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label class="form-label"> Year Established <span class="text-danger">*</span></label>
                        <select v-model="data.business_year" name="business_year"
                            :class="{ 'is-invalid': v$.data.business_year.$error  }" class="form-select">
                            <option disabled value=""> --select year-- </option>
                            <option v-for="(year, yIndex) in years" :key="yIndex"
                            :value="year.value">{{year.label}}</option>
                        </select>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label class="form-label"> Number of Employees <span class="text-danger">*</span></label>
                        <select v-model="data.number_employees" name="number_employees"
                            :class="{ 'is-invalid': v$.data.number_employees.$error  }" class="form-select">
                            <option disabled value=""> --select-- </option>
                            <option v-for="(numEmployee, yIndex) in employeeOptions" :key="yIndex"
                            :value="numEmployee.value">{{numEmployee.label}}</option>
                        </select>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label class="form-label"> Annual Revenue Range <span class="text-danger">*</span></label>
                        <select v-model="data.annual_revenue" name="annual_revenue"
                            :class="{ 'is-invalid': v$.data.annual_revenue.$error  }" class="form-select">
                            <option disabled value=""> --select-- </option>
                            <option v-for="(revenueOption, yIndex) in revenueOptions" :key="yIndex"
                            :value="revenueOption.value">{{revenueOption.label}}</option>
                        </select>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <label class="form-label"> Market Reach <span class="text-danger">*</span></label>
                        <select v-model="data.market_reach" name="market_reach"
                            :class="{ 'is-invalid': v$.data.market_reach.$error  }" class="form-select">
                            <option disabled value=""> --select-- </option>
                            <option v-for="(marketOption, yIndex) in marketOptions" :key="yIndex"
                            :value="marketOption.value">{{marketOption.label}}</option>
                        </select>
                    </div>
                    <div class="col-md-8">
                        <label for="business_address" class="form-label"> Business Address <span class="text-danger">*</span></label>
                        <input v-model="data.business_address" id="business_address" name="business_address"
                        class="form-control" type="text" placeholder="Business Address"
                        :class="{ 'is-invalid': v$.data.business_address.$error }">
                        <div v-for="(item, index) in v$.data.business_address.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label for="local_government_id" class="form-label">Business L.G.A. <span class="text-danger">*</span></label>
                        <select v-model="data.local_government_id" name="local_government_id"
                        id="local_government_id" class="form-select"
                        :class="{ 'is-invalid': v$.data.local_government_id.$error }">
                        <option disabled value="" >--choose LGA--</option>
                        <option v-for="(city, ctIndex) in lgaOptions" :key="ctIndex" 
                        :value="city.id">{{city.name}}</option>
                        </select>
                        <div v-for="(item, index) in v$.data.local_government_id.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                    </div>
                    <div class="col-12">
                        <hr  class="my-3"/>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label d-block">Do you pay taxes? <span class="text-danger">*</span></label>
                        <div class="form-check form-check-inline">
                            <input v-model="data.pays_tax" class="form-check-input" type="radio"
                            id="inlineRadioTrueTax" name="business_pays_tax" :value="true">
                            <label class="form-check-label" for="inlineRadioTrueTax">Yes, I pay taxes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input v-model="data.pays_tax" class="form-check-input" name="business_pays_tax" 
                            type="radio" id="inlineRadioFalseTax" :value="false">
                            <label class="form-check-label" for="inlineRadioFalseTax">No, I don't pay taxes</label>
                        </div>
                    </div>
                    <div v-if="data.pays_tax" class="col-md-6">
                        <label class="form-label" for="inputBusinessTin">TIN (Tax Identification Number) <span class="text-danger">*</span></label>
                        <input id="inputBusinessTin" autocomplete="false" type="text" class="form-control" v-model="data.business_tin"
                            :class="{ 'is-invalid': v$.data.business_tin.$error  }" placeholder="Enter TIN" name="business_tin" />
                        <div v-for="(item, index) in v$.data.business_tin.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row gy-3">
                    <div class="col-12" >
                        <h5 class="mb-2">Equipment and Machinery Needs</h5>
                    </div>
                    <div class="col-12">
                        <label class="form-label">Current Equipment and Machinery Used <span class="text-danger">*</span></label>
                        <textarea v-model="data.equipment_owned" class="form-control"
                        rows="3" placeholder="List current equipment and machinery here" name="equipment_owned"
                        :class="{ 'is-invalid': v$.data.equipment_owned.$error }"></textarea>
                        <div v-for="(item, index) in v$.data.equipment_owned.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                    </div>
                    <div class="col-12">
                        <label class="form-label">What type of equipment or machinery are you seeking support for? <span class="text-danger">*</span></label>
                        <textarea v-model="data.equipment_needed" class="form-control"
                        rows="3" placeholder="Describe the type of equipment/machinery needed" name="equipment_needed"
                        :class="{ 'is-invalid': v$.data.equipment_needed.$error }"></textarea>
                        <div v-for="(item, index) in v$.data.equipment_needed.$errors"
                            :key="index" class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                    </div>

                    <div class="col-12">
                        <label class="form-label">What challenges are you currently facing with your existing equipment?</label>
                        <textarea v-model="data.equipment_challenges" class="form-control"
                        rows="3" placeholder="Challenges with your existing equipment" name="equipment_challenges"></textarea>
                    </div>
                    <div class="col-12">
                        <label class="form-label">How do you believe new equipment or machinery would benefit your business?</label>
                        <textarea v-model="data.equipment_benefit" class="form-control"
                        rows="3" placeholder="Write something" name="equipment_benefit"></textarea>
                    </div>
                    <div class="col-12">
                        <label class="form-label d-block">Would you like to receive updates about the program and other related opportunities?</label>
                        <div class="form-check form-check-inline">
                            <input v-model="data.updates_consent" class="form-check-input" type="radio"
                            id="inlineRadioTrueUpdates" name="updates_consent" :value="true">
                            <label class="form-check-label" for="inlineRadioTrueUpdates">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input v-model="data.updates_consent" class="form-check-input" name="updates_consent" 
                            type="radio" id="inlineRadioFalseUpdates" :value="false">
                            <label class="form-check-label" for="inlineRadioFalseUpdates">No</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <label class="form-label">Do you have any concerns regarding the data collection process that we should be aware of?</label>
                        <textarea v-model="data.data_collection_consern" class="form-control"
                        rows="3" placeholder="Write something" name="data_collection_consern"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row gy-3 mb-4">
                    <div class="col-12" >
                        <h5 class="mb-2">Attachments</h5>
                        <p>Please Attach only PNG, JPG File</p>
                    </div>
                    <div class="col-12">
                        <div class="row g-3">
                            <div v-for="(attachment, atchIndex) in data.attachments"
                                :key="atchIndex" class="col-12">
                                <div class="card mb-0 card-body bg-light">
                                    <h6 class="text-muted mb-3 d-sm-flex justify-content-between">
                                        <div class="mb-2 mb-sm-0">Attachment #{{ atchIndex +1 }}</div>
                                        <span class="">
                                            <span v-if="atchIndex > 1" @click="removeAttachment(atchIndex)" class="badge bg-danger me-2 cursor-pointer">Remove</span>
                                            <span v-if="atchIndex == data.attachments.length - 1" @click="addAttachment()" class="badge bg-primary cursor-pointer">Add File</span>
                                        </span>
                                    </h6>
                                    <div class="d-md-flex align-items-end">
                                        <img width="150" class="me-3 mb-3 mb-md-0 me-lg-5"
                                        :src="absoluteUrl(attachment.path || '/images/svg/image-placeholder.svg')" />
                                        <div class="flex-grow-1">
                                            <div class="row g-3 align-items-end">
                                                <div class="col-12">
                                                    <label>Select File Type</label>
                                                    <select
                                                        @change="fileTypeChange($event, atchIndex)"
                                                        v-model="attachment.value"
                                                        :disabled="atchIndex < 2"
                                                        class="form-select text-start">
                                                        <option disabled value="">--File Type--</option>
                                                        <option v-for="(uploadOption, opIndex) in uploadOptions"
                                                        :disabled="data.attachments.map(i => i.value).includes(uploadOption.value)"
                                                        :key="opIndex" :value="uploadOption.value">{{ uploadOption.label }}</option>
                                                    </select>
                                                </div>
                                                <div class="col-12" v-if="attachment.value">
                                                    <attachment-capture :maxSize="5000000"
                                                    @uploadSuccess="updateAttachment($event, atchIndex)" />
                                                    <div v-for="(item, index) in v$.data.business_tin.$errors"
                                                        :key="index" class="invalid-feedback">
                                                        <span v-if="item.$message">{{ item.$message }}</span>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-check my-3">
          <input v-model="data.data_usage_consent" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">
            I Consent: By checking this box, you consent to the collection and use of information provided in this assessment for the purpose of evaluating your eligibility for the Arise Sustainable Business Equipment and Machinery Support Programme.
          </label>
        </div>
        <div class="row mt-3 justify-content-end">
            <div class="col-12">
                <div class="mt-3 d-flex justify-content-between">
                    <button class="btn btn-primary px-lg-5 btn-lg me-2" @click.prevent="confirm()"
                    type="button"> Submit </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, email, minLength, requiredIf, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import IsLoading from '@/components/IsLoading.vue'
import AttachmentCapture from './AttachmentCapture.vue';
import ProfileImageCapture from './ProfileImageCapture.vue';
import Multiselect from '@vueform/multiselect'

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
       IsLoading,
       AttachmentCapture,
       ProfileImageCapture,
       Multiselect
    },
    props:{
        form:{
            type: Object,
            default: null
        }
    },
    data(){
        return{
            isLoading: false,
            data: {
                form_id: this.form.id,
                trade_id: "",
                business_name: "",
                business_type: "",
                business_type_other: "",
                business_year: "",
                number_employees: "",
                annual_revenue: "",
                market_reach: "",
                business_address: "",
                local_government_id: "",
                pays_tax: false,
                business_tin: null,

                data_usage_consent: false,
                updates_consent:false,
                data_collection_consern: "",

                equipment_owned: "",
                equipment_needed: "",
                equipment_challenges: "",
                equipment_benefit: "",

                beneficiary:{
                    image: "",
    
                    first_name: "",
                    last_name: "",
                    middle_name: "",
                    email: "",
                    mobile: "",
                    gender: null,
                    dob: null,
                    position: "",
                    
                    address: "",
                    local_government_id: "",
                    registration_area_id: "",
                    polling_unit_id:"",

                    nin: "",
                    vin: "",
                },
                bank_account:{
                    bank_id: "",
                    account_name: "",
                    account_number: ""
                },
                attachments:[
                    {
                        label: "Business front shop",
                        value: 'front_shop',
                    },{
                        label: "Beneficiary's Signature",
                        value: "beneficiary_signature",
                    }
                ]
            },
            mirrorData: null,
            lgaOptions:[],
            bankOptions: [],
            validAccount:{
                status: false,
                loading: false,
                message: ""
            },
            uploadOptions:[
                {
                    label: "Business front shop",
                    value: "front_shop",
                },{
                    label: "Corporate Affairs Commission (CAC)",
                    value: "cac_certificate",
                },{
                    label: "Tax Identification Number (TIN)",
                    value: "tin_document",
                },{
                    label: "Beneficiary's Signature",
                    value: "beneficiary_signature",
                }
            ],
            genderOptions: [
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
                { label: 'Others', value: 'others' }
            ],
            businessTypes: [
               { value: "Retail", label: "Retail"},
               { value: "Manufacturing", label: "Manufacturing"},
               { value: "Service", label: "Service"},
               { value: "Food and Beverage", label: "Food and Beverage"},
               { value: null, label: "Others"}
            ],
            employeeOptions: [
               { value: "1-5", label: "1-5"},
               { value: "6-10", label: "6-10"},
               { value: "11-20", label: "11-20"},
               { value: "21-50", label: "21-50"},
               { value: "More than 50", label: "More than 50"}
            ],
            revenueOptions: [
               { value: "Less than N100,000", label: "Less than N100,000"},
               { value: "N100,001 – N500,000", label: "N100,001 – N500,000"},
               { value: "N500,001 – N1,000,000", label: "N500,001 – N1,000,000"},
               { value: "More than N1,000,000", label: "More than N1,000,000"},
            ],
            marketOptions: [
               { value: "Local", label: "Local"},
               { value: "Regional", label: "Regional"},
               { value: "National", label: "National"},
               { value: "International", label: "International"},
            ],
            manualDate:{
                day: "",
                month: "",
                year: ""
            },
            wardOptions: [],
            unitOptions: [],
            tradeOptions: []
        }
    },
    computed:{
        days() {
            return Array.from({ length: 31 }, (_, index) => {
                const dayValue = index + 1;
                return {
                    value: dayValue,
                    label: dayValue.toString().padStart(2, '0')
                };
            });
        },
        months() {
            const monthNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            return Array.from({ length: 12 }, (_, index) => {
                const monthValue = index + 1;
                return {
                    value: monthValue,
                    label: monthNames[index]
                };
            });
        },
        years() {
            let factor = 0;
            let startYear = new Date().getFullYear() - factor;
            return Array.from({ length: 100 }, (_, index) => {
                const yearValue = startYear - index;
                return {
                    value: yearValue,
                    label: yearValue.toString()
                };
            });
        },
    },
    validations() {
        return {        
            data:{
                trade_id: {
                    required: helpers.withMessage("Trade is required", required),
                },
                business_name: {
                    required: helpers.withMessage("Business name is required", required),
                },
                business_type: {
                    requiredIf: requiredIf(!this.data.business_type_other),
                },
                business_year: {
                    required: helpers.withMessage("Business establised year is required", required),
                },
                number_employees: {
                    required: helpers.withMessage("Number of employees is required", required),
                },
                annual_revenue: {
                    required: helpers.withMessage("Anual Revenue is required", required),
                },
                market_reach: {
                    required: helpers.withMessage("Market reach is required", required),
                },
                business_address: {
                    required: helpers.withMessage("Business address is required", required),
                },
                local_government_id:{
                    required: helpers.withMessage("Business local government is required", required),
                },
                business_tin: {
                    requiredIf: requiredIf(this.data.pays_tax),
                },
                equipment_owned:{
                    required: helpers.withMessage("Describe Equipment owns", required),
                },
                equipment_needed:{
                    required: helpers.withMessage("Describe Equipment needed", required),
                },
                beneficiary:{
                    first_name: {
                        required: helpers.withMessage("First name is required", required),
                    },
                    last_name: {
                        required: helpers.withMessage("Last name is required", required),
                    },
                    mobile: {
                        required: helpers.withMessage("Phone number is required", required),
                    },
                    gender: {
                        required: helpers.withMessage("Select gender", required),
                    },   
                    dob: {
                        required: helpers.withMessage("Date of birth is required", required),
                    }, 
                    image: {
                        required: helpers.withMessage("Profile photo is required", required),
                    },
                    position: {
                        required: helpers.withMessage("Position is required", required),
                    },
                    address: {
                        required: helpers.withMessage("Beneficiary Address is required", required),
                    },
                    local_government_id: {
                        required: helpers.withMessage("Beneficiary local government is required", required),
                    },
                    registration_area_id: {
                        required: helpers.withMessage("Beneficiary ward is required", required),
                    },
                    polling_unit_id: {
                        required: helpers.withMessage("Beneficiary polling unit is required", required),
                    },
                    nin: {
                        required: helpers.withMessage("Beneficiary NIN is required", required),
                    },
                    vin: {
                        required: helpers.withMessage("Beneficiary VIN is required", required),
                    },
                },
                bank_account:{
                    bank_id: {
                        required: helpers.withMessage("Select a bank", required),
                    },
                    account_number: {
                        required: helpers.withMessage("Account number is required", required),
                    },
                    account_name: {
                        required: helpers.withMessage("Account name is required", required),
                    },
                },
            }
        }
    },
    methods:{
        async confirm() {
            const result = await this.v$.$validate();
            if (!result) {
                this.alertError(this.v$.$errors[0].$message);
                return;
            }
            if (!this.data.data_usage_consent) { 
              this.alertError("Please accept the data usage consent to proceed."); return;
            }
            if (!await this.checkAttachments()) {
                return;
            }
            const confirmResult = await this.confirmAction({
                text: "<h6>Acknowledge that all entered details are accurate.</h6>",
            });
            if (confirmResult.value) {
                this.create();
            }
        },
        async checkAttachments() {
            for (let index = 0; index < this.data.attachments.length; index++) {
                const item = this.data.attachments[index];
                if (!item.path || !item.name || !item.value) {
                    let error = `Invalid file at attachment ${index+1}#. Please upload the required document.`;
                    this.alertError(error);
                    return false;
                }
            }
            return true;
        },
        create() {
            let formData = new FormData();
            formData.append('data', JSON.stringify(this.data))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post('/applications/create', formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.$emit('addItem', response.data.data)
                    this.resetForm();
                }
            })
        },
        createDOB(){
            var inputDate = this.manualDate;
            if(!inputDate.day || !inputDate.month || !inputDate.year){
                this.data.beneficiary.dob = ""; return;
            }
            var date = new Date(inputDate.year, inputDate.month - 1, inputDate.day);
            this.data.beneficiary.dob =  date;
        },
        verifyAccount(){
            this.data.bank_account.account_name = this.validAccount.message = "";
            if(!this.data.bank_account.bank_id || !this.data.bank_account.account_number) { return;}
            let payload = { bank_id: this.data.bank_account.bank_id, account_number: this.data.bank_account.account_number}
            this.validAccount.loading = true
            this.validAccount.status = false
            this.$http.get(`${this.absoluteUrl()}/api/verify/bank-account?${new URLSearchParams(payload).toString()}`)
                .then((response) => {
                this.validAccount.loading = false
                if(response.data.success){
                    this.validAccount.status = true
                    this.data.bank_account.account_name = response.data.data.account_name
                }else{
                    this.validAccount.message = response.data.data.message
                }
            })
        },
        fileTypeChange(e, index){
            const item = this.uploadOptions.find(i => i.value == e.target.value)
            this.data.attachments[index] = JSON.parse(JSON.stringify(item))
        },
        updateAttachment(data, index){
            let item = this.data.attachments[index];
            data.name = item.label;
            Object.assign(this.data.attachments[index], data)
        },
        addAttachment(){
            this.data.attachments.push({label: "", value: ""})
        },
        removeAttachment(itemIndex){
            if(!itemIndex) return
            this.data.attachments.splice(itemIndex, 1);
        },
        resetForm(){
            this.data = {...this.mirrorData}
            this.v$.$reset()
            this.$emit("closeMe")
        },
        lgaChange(){
            this.data.beneficiary.registration_area_id = this.data.beneficiary.polling_unit_id  = "";
            this.wardOptions = this.unitOptions = [];
            this.isLoading = true
            this.$http.get(`${this.absoluteUrl()}/api/registration-areas?local_government_id=${this.data.beneficiary.local_government_id}`)
                .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.wardOptions = response.data.data
                }
            })
        },
        wardChange(){

            this.data.beneficiary.polling_unit_id = "";
            this.unitOptions = [];
            if(!this.data.beneficiary.local_government_id || !this.data.beneficiary.registration_area_id){
                return
            }
            this.isLoading = true
            this.$http.get(`${this.absoluteUrl()}/api/polling-units?local_government_id=${this.data.beneficiary.local_government_id}&registration_area_id=${this.data.beneficiary.registration_area_id}`)
                .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.unitOptions = response.data.data
                }
            })
        },
        getOptionData(){
            this.isLoading = true
            this.$http.get(`/trades/all`)
                .then((response) => {
                if(response.data.success){
                    this.tradeOptions = response.data.data
                }
            })
            this.$http.get(`${this.absoluteUrl()}/api/banks`)
                .then((response) => {
                if(response.data.success){
                    this.bankOptions = response.data.data
                }
            })
            this.$http.get(`${this.absoluteUrl()}/api/local-governments`)
                .then((response) => {
                    this.isLoading = false
                    if(response.data.success){
                        this.lgaOptions = response.data.data
                    }
                })
        },
    },
    created(){
        this.getOptionData()
    },
    mounted(){
        this.mirrorData = JSON.parse(JSON.stringify(this.data))
    }
}
</script>

<style scoped>
</style>