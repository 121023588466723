
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of beneficiaries invited</h5>
      <div class="row gy-2 justify-content-between">
        <div class="col-sm-12 col-md-4 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <li @click.prevent="exportClients()">
                <button class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li>
            </ul>
          </div>
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select v-model="defaultStatus" class="form-select flex-grow-1">
              <option value="" disabled selected>-- sort by status --</option>
              <option v-for="(status, tIndex) in statusOptions"
                :value="status.value" :key="tIndex" > {{status.label}} </option>
            </select>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="my-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm" align="middle">
              <template v-slot:cell(beneficiary)="data">
                <div @click="navigateTo(data.item.application.id)" style="max-width:20rem;"  class="cursor-pointer">
                  <div class="d-flex mb-2">
                    <img class="avatar me-2 avatar-sm rounded-circle" :src="absoluteUrl(data.item.application.beneficiary.image)" />
                    <span class="text-dark fw-bold font-sm text-capitalize">{{ data.item.application.beneficiary.first_name}} {{ data.item.application.beneficiary.last_name}}</span>
                  </div>
                  <div class="text-dark font-sm">{{ data.item.application.beneficiary.mobile }}</div>
                  <div class="text-dark font-sm">
                    <span>{{ data.item.application.beneficiary.address }} </span>
                    <span v-if="data.item.application.beneficiary.lga">, {{ data.item.application.beneficiary.lga.name }}</span>
                  </div>
                </div>
              </template>
              <template v-slot:cell(application)="data">
                <div style="max-width:20rem;" @click="navigateTo(data.item.application.id)"  class="cursor-pointer">
                 <h6>{{ data.item.application.friendly_id }}</h6>
                 <div>
                  <span v-html="statusBadge(data.item.application.status)" class="font-sm"></span>
                 </div>
                 <div>Business name: <strong>{{ data.item.application.business_name }}</strong></div>
                 <div>Business Address: <strong>{{ data.item.application.business_address }}</strong></div>
                 <div>Business Needs: <strong>{{ data.item.application.equipment_needed }}</strong></div>
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="dropdown dropstart no-arrow">
                  <button class="dropdown-toggle btn btn-sm btn-light" type="button" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <button @click="navigateTo(data.item.application.id)" class="dropdown-item" 
                      type="button"> View Application </button>
                    </li>
                  </ul>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';

export default {
  name: "invitations",
  components:{
    IsLoading
  },
  data() {
    return {
      isLoading: true,
      defaultStatus: null,
      searchQuery:"",
      statusOptions:[
        { value: null, label: "All", },
        { value: "1", label: "Precent" },
        { value: "0", label: "Absent" }
      ],
      pageOptions: [10, 50, 100, 500, 1000],
      columns: [
        {
          key: "beneficiary",
          label: "Beneficiary",
        },
        {
          key: "application",
          label: "Application",
        },
        "action"
      ],
      pageData:{
        data: []
      },
      selected: [],
    }
  },
  watch: {
    defaultStatus (val) {
      this.fetchItems()
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    navigateTo(id){
      this.$router.push({path:`/applications/${id}`});
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage}

      if(this.defaultStatus){ payload.is_present = this.defaultStatus }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$http.get(`/invitations?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.pageData = response.data.data
          }
        })
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You want to delete this invitee from this collection",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/invitations/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              const ItemIndex = this.pageData.data.findIndex((item) => item.id == itemId)
              this.pageData.data.splice(ItemIndex, 1);
            }
          })
        }
      });
    },
    exportClients() {
      const payload = {
        url: `/invitations/export-excel`,
        filename: 'invitations_'
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.$store.dispatch('serverExport', payload)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
            this.alertError('Could not generate clients');
          }
      })
    },
  },
  created() {
    this.fetchItems()
  }
}

</script>

